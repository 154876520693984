import React, { Component } from 'react';


class CaseMain extends Component {

  componentDidMount(){
    this.props.showHideNav(false, true, false, true);
  }

  render() {
    return (
      <article className="case-main">
        <h1>You're among friends</h1>
        <p className="p1">No showcase can bring enlightenment to what we do and how we do it.
        Therefore we look forward to meeting you in person to talk about what you need.</p>
        <p className="p2">That’s how our previous work with Altia, Arla, Budget/Avis, Reebok, Reitan Convenience, Santa Maria, SAS and Verisure to name a few all started...</p>
        <p className="p3">Want to talk? Swipe right. </p>
      </article>
    );
  }
}

export default CaseMain;
