import React, { Component } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

class Cases extends Component {


  render() {
    return (
      <div className="cases">
        <TransitionGroup className="content-wrapper">
          <CSSTransition
            key={this.props.appState.currentViewThree.id}
            timeout={this.props.appState.aniTime}
            classNames={this.props.appState.animationClassTwo}
            showHideNav={this.props.showHideNav}
          >
            {this.props.appState.currentViewThree.view}
          </CSSTransition>
        </TransitionGroup>
      </div>
    );
  }
}

export default Cases;
