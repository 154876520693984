import React, { Component } from 'react';


class WhatwedoThree extends Component {

  componentDidMount(){
    this.props.showHideNav(true, true, true, true);

  }

  render() {
    return (
      <article className="whatwedo-three">
        <h1>66 days to change a habit</h1>
        <p className="p1">We are strong believers that small actions every day outbeats big bang thinking.
        Years of consultancy for international  superbrands and management training means we have many tools and tricks up our sleeve’s.</p>
        <p className="p2"><strong>As interim coaches we help you navigate, lead and enable strong teams</strong> and partners to reach your vision. One day at a time.</p>
        <p className="p3">Swipe right to find out who we’ve worked with</p>
      </article>
    );
  }
}

export default WhatwedoThree;
