import React, { Component } from 'react';


class CaseFive extends Component {

  componentDidMount(){
    this.props.showHideNav(true, true, true, true);
  }

  render() {
    return (
      <article className="case-five">
        <h1>We make it <br/> work</h1>
        <p>
          Digital thinkers and makers of tomorrow.
          Digital thinkers and makers of tomorrow.
          Digital thinkers and makers of tomorrow.
        </p>
      </article>
    );
  }
}

export default CaseFive;
