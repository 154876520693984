import React, { Component } from 'react';

class WhatwedoMain extends Component {

  componentDidMount(){
    this.props.showHideNav(false, true, true, true);
  }

  render() {
    return (
      <article className="whatwedo-main">
        <h1>We continue<br/>where others stop</h1>
        <div>
          <p>Our way of working serves ONE single purpose - to maximize your end result.</p>
          <p>
            No matter your organisational structure or existing agency partnerships -
            <strong> we help you find “the how” </strong>
            to combine insights, strategy, creativity and technology in line with your roadmap.
          </p>
          <p>Want to know more about how we do it?</p>
          <p className="p4">Swipe down.</p>
        </div>
      </article>
    );
  }
}

export default WhatwedoMain;
