import React, { Component } from 'react';
import BudgetLogo from './../../../media/img/BudgetLogo.png';

class CaseTwo extends Component {

  componentDidMount(){
    this.props.showHideNav(true, true, true, true);

  }


  render() {
    return (
      <article className="case-two">
        <div className="h1-container">
          <h1>Car rental</h1>
          <h1>re-invented</h1>
        </div>
        <p>
          Digital thinkers and makers of tomorrow.
          Digital thinkers and makers of tomorrow.
          Digital thinkers and makers of tomorrow.
        </p>
        <div className="img-container">
          <img alt="budget Logo" src={BudgetLogo} />
        </div>
      </article>
    );
  }
}

export default CaseTwo;
