import React, { Component } from 'react';
import ArlaLogo from './../../../media/img/arlaLogo.png';

class CaseThree extends Component {

  componentDidMount(){
    this.props.showHideNav(true, true, true, true);
  }

  render() {
    return (
      <article className="case-three">
        <div className="img-container">
          <img alt="Arla Logo" src={ArlaLogo} />
        </div>
        <h1>Co-creating <br/> storytelling</h1>
        <p>
          Digital thinkers and makers of tomorrow.
          Digital thinkers and makers of tomorrow.
          Digital thinkers and makers of tomorrow.
          Digital thinkers and makers of tomorrow.
          Digital thinkers and makers of tomorrow.
        </p>
      </article>
    );
  }
}

export default CaseThree;
