import React, { Component } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Top from './../../stateless/layout/Top';
import Landing from './../../stateless/pages/Landing';
import Whatwedo from './../../stateless/layout/Whatwedo';
import Cases from './../../stateless/layout/Cases';
import Contact from './../../stateless/pages/Contact';
import WhatwedoMain from './../../stateless/pages/whatwedo/WhatwedoMain';
import WhatwedoTwo from './../../stateless/pages/whatwedo/WhatwedoTwo';
import WhatwedoThree from './../../stateless/pages/whatwedo/WhatwedoThree';
import CaseMain from './../../stateless/pages/cases/CaseMain';
import CaseTwo from './../../stateless/pages/cases/CaseTwo';
import CaseThree from './../../stateless/pages/cases/CaseThree';
import CaseFour from './../../stateless/pages/cases/CaseFour';
import CaseFive from './../../stateless/pages/cases/CaseFive';
import './../../App.css';

const animationTime = 600;
const views = [
  {view: <Landing />,
   id: 0},
  {view: <Whatwedo />,
   id: 1},
  {view: <Cases />,
   id: 2},
  {view: <Contact />,
   id: 3}
];
const viewsTwo = [
  {view: <WhatwedoMain />,
   id: 0},
  {view: <WhatwedoTwo />,
   id: 1},
  {view: <WhatwedoThree />,
   id: 2}
];
const viewsThree = [
  {view: <CaseMain />,
   id: 0},
  {view: <CaseTwo />,
   id: 1},
  {view: <CaseThree />,
   id: 2},
  {view: <CaseFour />,
   id: 3},
  {view: <CaseFive />,
   id: 4}
];
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentView:       views[0],
      currentViewTwo:    viewsTwo[0],
      currentViewThree:  viewsThree[0],
      aniTime:           animationTime,
      animationClass:    'slide',
      animationClassTwo: 'down',
      logoid:            'landing',
      navUp:             false,
      navNext:           true,
      navDown:           false,
      navPrev:           false,
      preventScroll:     false,
      tStartX:           null,
      tStartY:           null
    };
    this.nextView         = this.nextView.bind(this);
    this.prevView         = this.prevView.bind(this);
    this.downView         = this.downView.bind(this);
    this.upView           = this.upView.bind(this);
    this.showHideNav      = this.showHideNav.bind(this);
    this.handleScroll     = this.handleScroll.bind(this);
    this.handleTouchStart = this.handleTouchStart.bind(this);
    this.handleTouchEnd   = this.handleTouchEnd.bind(this);
    this.stopFunction     = this.stopFunction.bind(this);
  }

  componentDidUpdate(){
    if(this.state.logoid === 'landing' && this.state.currentView.id > 0) {
      this.setState({ logoid: 'otherViews' });
    } else if (this.state.logoid === 'otherViews' && this.state.currentView.id === 0){
      this.setState({ logoid: 'firstView' });
    } else if (this.state.logoid === 'firstView' && this.state.currentView.id > 0) {
      this.setState({ logoid: 'otherViews' });
    }
  }

  render() {
    return (
      <div className="App"
           onWheel={this.handleScroll}
           onTouchStart={this.handleTouchStart}
           onTouchEnd={this.handleTouchEnd}
           onMouseDown={this.handleTouchStart}
           onMouseUp={this.handleTouchEnd}
      >
        <Top
          nextView={this.nextView}
          prevView={this.prevView}
          downView={this.downView}
          upView={this.upView}
          appState={this.state}
        />
        <TransitionGroup className="Apps">
          <CSSTransition
            key={this.state.currentView.id}
            timeout={this.state.aniTime}
            classNames={this.state.animationClass}
            appState={this.state}
            showHideNav={this.showHideNav}
            nextView={this.nextView}
          >
            {this.state.currentView.view}
          </CSSTransition>
        </TransitionGroup>
      </div>
    );
  }

  nextView(){
    this.setState({
      animationClass: 'slide'
    });
    setTimeout(() => {
      if(this.state.animationClass === 'slide') {
        let next;
        if(this.state.currentView.id < views.length-1) {
          next = this.state.currentView.id+1;
        } else {
          next = 0;
        }
        this.setState({
          currentView: views[next]
        });
      }
    }, 10);
    if(this.state.currentView.id === 1){
      setTimeout(() => {
        this.setState({
          currentViewTwo: viewsTwo[0]
        });
      }, this.state.aniTime);
    }
    if(this.state.currentView.id === 2){
      setTimeout(() => {
        this.setState({
          currentViewThree: viewsThree[0]
        });
      }, this.state.aniTime);
    }
    this.stopFunction();
  }
  prevView(){
    this.setState({
      animationClass: 'sliderev'
    });
    setTimeout(() => {
      if(this.state.animationClass === 'sliderev') {
        let prev;
        if(this.state.currentView.id !== 0) {
          prev = this.state.currentView.id-1;
        } else {
          prev = 3;
        }
        this.setState({
          currentView: views[prev]
        });
      }
    }, 10);
    if(this.state.currentView.id === 1){
      setTimeout(() => {
        this.setState({
          currentViewTwo: viewsTwo[0]
        });
      }, this.state.aniTime);
    }
    if(this.state.currentView.id === 2){
      setTimeout(() => {
        this.setState({
          currentViewThree: viewsThree[0]
        });
      }, this.state.aniTime);
    }
    this.stopFunction();
  }

  downView(){
    this.setState({
      animationClassTwo: 'down'
    });
    let down;
    setTimeout(() => {
      if(this.state.currentView.id === 1 && this.state.animationClassTwo === 'down') {
        if(this.state.currentViewTwo.id < viewsTwo.length-1) {
          down = this.state.currentViewTwo.id+1;
        } else {
          down = 0;
        }
        this.setState({
          currentViewTwo: viewsTwo[down]
        });
      }
      if(this.state.currentView.id === 2 && this.state.animationClassTwo === 'down') {
        if(this.state.currentViewThree.id < viewsThree.length-1) {
          down = this.state.currentViewThree.id+1;
        } else {
          down = 0;
        }
        this.setState({
          currentViewThree: viewsThree[down]
        });
      }
    }, 10);
  }

  upView(){
    this.setState({
      animationClassTwo: 'up'
    });
    let up;
    setTimeout(() => {
      if(this.state.currentView.id === 1 && this.state.animationClassTwo === 'up') {
        if(this.state.currentViewTwo.id !== 0) {
          up = this.state.currentViewTwo.id-1;
        } else {
          up = 2;
        }
        this.setState({
          currentViewTwo: viewsTwo[up]
        });
      }
      if(this.state.currentView.id === 2 && this.state.animationClassTwo === 'up') {
        if(this.state.currentViewThree.id !== 0) {
          up = this.state.currentViewThree.id-1;
        } else {
          up = 4;
        }
        this.setState({
          currentViewThree: viewsThree[up]
        });
      }
    }, 10);
  }
  showHideNav(up, next, down, prev){
    this.setState({
      navUp: false,
      navNext: false,
      navDown: false,
      navPrev: false
    });
    setTimeout(() => {
      this.setState({
        navUp: up,
        navNext: next,
        navDown: down,
        navPrev: prev
      });
    }, this.state.aniTime);
  }

  handleTouchStart(e){
    e = e || window.event;
    if(e.type === 'touchstart'){
      this.setState({
        tStartX: e.touches[0].pageX,
        tStartY: e.touches[0].pageY
      });
    }
    if(e.type === 'mousedown'){
      this.setState({
        tStartX: e.pageX,
        tStartY: e.pageY
      });
    }
  }
  handleTouchEnd(e){
    e = e || window.event;
    let tEndX,
        tEndY;
    if(e.type === 'touchend'){
      tEndX = e.changedTouches[0].pageX;
      tEndY = e.changedTouches[0].pageY;
    }
    if(e.type === 'mouseup'){
      tEndX = e.pageX;
      tEndY = e.pageY;
    }
    let deltaX = this.state.tStartX - tEndX;
    let deltaY = this.state.tStartY - tEndY;
    let deltaYPositive = Math.abs(deltaY);
    let deltaXPositive = Math.abs(deltaX);
    if(!this.state.preventScroll) {
      if(deltaYPositive > 30 || deltaXPositive > 30) {
        if(deltaX > 0 && deltaX > deltaYPositive && this.state.navNext){
          this.nextView();
        } else if(deltaX < 0 && deltaXPositive > deltaYPositive && this.state.navPrev){
          this.prevView();
        } else if(deltaY > 0 && deltaY > deltaXPositive && this.state.navDown){
          this.downView();
        } else if(deltaY < 0 && deltaYPositive > deltaXPositive && this.state.navUp){
          this.upView();
        }
      }
    }
  }

  handleScroll(e){
    e = e || window.event;
    if(!this.state.preventScroll) {
      if(window.event.wheelDelta < 0 && e.type === "wheel") {
        this.state.navNext ? this.nextView() : console.log('not possible')
      } else if (window.event.wheelDelta > 0 && e.type === "wheel") {
        this.state.navPrev ? this.prevView() : console.log('not possible')
      }
    }
  }
  stopFunction(){
    this.setState({ preventScroll: true });
    setTimeout(() => {
      this.setState({ preventScroll: false });
    }, 600);
  }

}

export default App;
