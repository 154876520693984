import React, { Component } from 'react';

class Contact extends Component {

  componentDidMount(){
    this.props.showHideNav(false, true, false, true);
  }

  render() {
    return (
      <article className="contact">
        <h1>Lets start a <br/> conversation</h1>
        <p className="p1">Call us on</p>
        <h2 className="h2-1">+46 70 5152200</h2>
        <p className="p2">or drop an email at</p>
        <h2 className="h2-2">hello@jointhecarnival.com</h2>
      </article>
    );
  }
}

export default Contact;
