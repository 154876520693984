import React, { Component } from 'react';


class WhatwedoTwo extends Component {

  componentDidMount(){
    this.props.showHideNav(true, true, true, true);

  }

  render() {
    return (
      <article className="whatwedo-two">
        <h1>Competing on customer experience</h1>
        <p className="p1">
          We help you develop strategies and concepts that goes beyond marketing campaigns,
          with a clear focus on achieving business and communication ROI that drive your competetive edge in an experience economy.
        </p>
        <h2>...and we <br/>implement  <br/>sustainably</h2>
        <p className="p2">Swipe down to learn more.</p>
      </article>
    );
  }
}

export default WhatwedoTwo;
