import React, { Component } from 'react';
import EyeodaLogo from './../../../media/img/eyeodaLogo.png';

class CaseFour extends Component {

  componentDidMount(){
    this.props.showHideNav(true, true, true, true);

  }

  render() {
    return (
      <article className="case-four">
        <div className="img-container">
          <img alt="Eyeoda Logo" src={EyeodaLogo} />
        </div>
        <div className="h1-container">
          <h1>You're among</h1>
          <h1>friends</h1>
        </div>
        <p>
          Digital thinkers and makers of tomorrow.
          Digital thinkers and makers of tomorrow.
          Digital thinkers and makers of tomorrow.
          Digital thinkers and makers of tomorrow.
          Digital thinkers and makers of tomorrow.
        </p>
      </article>
    );
  }
}

export default CaseFour;
