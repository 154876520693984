import React, { Component } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';



class Whatwedo extends Component {
  render() {
    return (
      <div className="whatwedo">
        <TransitionGroup className="content-wrapper">
          <CSSTransition
            key={this.props.appState.currentViewTwo.id}
            timeout={this.props.appState.aniTime}
            classNames={this.props.appState.animationClassTwo}
            showHideNav={this.props.showHideNav}
          >
            {this.props.appState.currentViewTwo.view}
          </CSSTransition>
        </TransitionGroup>
      </div>
    );
  }
}

export default Whatwedo;
